/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { client } from "../utils/graphql";
import Layout from "./Layout";
import { useParams } from "react-router";
import moment from "moment";

require("../template.css");

const GET_COMMENTS = gql`
  query (
    $token: String
    $authorChannelUrl: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getComments(
      input: {
        token: $token
        authorChannelUrl: $authorChannelUrl
        pagination: { limit: 10, page: 1 }
        timePeriodComments: { Start: $startTime, End: $endTime }
      }
    ) {
      comments {
        id
        content
        videoId
        likes
        publishedAt
      }
      timePeriodComments {
        monthly {
          month
          count
        }
        weekly {
          week
          count
        }
      }
    }
  }
`;

const GET_CHANNEL_DATA = gql`
  query ($token: String) {
    getChannelData(input: { token: $token }) {
      id
      name
      status
      videoCount
      subscriberCount
      commentCount
      lastPullTimestamp
      authors(input: { pagination: { page: 1, limit: 20 } }) {
        commentCount
        author
        authorChannelUrl
        authorAvatarUrl
      }
    }
  }
`;

const FanProfile = (props) => {
  const [channelData, setChannelData] = useState({
    authors: [],
    lastPullTimestamp: 1637903396,
  });
  const [comments, setComments] = useState([]);
  const [fanData, setFanData] = useState({});
  const { id } = useParams();
  const [commentsTimePeriodOptions, setCommentsTimePeriodOptions] = useState([
    { label: "Last 30 days", value: 30, active: false },
    { label: "Last 3 months", value: 90, active: false },
    { label: "Last 6 months", value: 180, active: false },
    { label: "Last 12 months", value: 365, active: true },
  ]);
  const [options, setOptions] = useState({
    xaxis: {
      categories: [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    colors: ["#6C5DD3"],
    chart: {
      height: "100%",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#E4E4E4",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        left: 10,
        right: 0,
        bottom: 0,
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      shared: false,
    },
    yaxis: {
      axisBorder: {
        color: "#E4E4E4",
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Comments",
      data: [],
    },
  ]);
  const [isCommentsDateDropDownActive, setIsCommentsDateDropDownActive] =
    useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    localStorage.setItem("darkMode", "on");

    const { data } = await client.query({
      query: GET_CHANNEL_DATA,
      variables: {
        token: localStorage.getItem("token"),
      },
    });
    console.log(data);
    setChannelData(data.getChannelData);
    localStorage.setItem(
      "lastFetchedTimeStamp",
      data.getChannelData.lastPullTimestamp
    );
    setFanData(
      data.getChannelData.authors.filter(
        (item) =>
          item.authorChannelUrl === `http://www.youtube.com/channel/${id}`
      )[0]
    );

    const commentsData = await client.query({
      query: GET_COMMENTS,
      variables: {
        token: localStorage.getItem("token"),
        authorChannelUrl: `http://www.youtube.com/channel/${id}`,
        startTime: Math.floor(
          new Date(
            new Date().setDate(
              new Date().getDate() -
                commentsTimePeriodOptions.filter((item) => item.active)[0].value
            )
          ).getTime() / 1000
        ),
        endTime: Math.floor(new Date().getTime() / 1000),
      },
    });
    if (
      commentsTimePeriodOptions.filter((item) => item.active)[0].value === 30
    ) {
      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories:
            commentsData.data.getComments.timePeriodComments.weekly.map(
              (item) => `Week - ${item.week.split("-")[1]}`
            ),
        },
      });
      setSeries([
        {
          name: "Comments",
          data: commentsData.data.getComments.timePeriodComments.weekly.map(
            (item) => item.count
          ),
        },
      ]);
    } else {
      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories:
            commentsData.data.getComments.timePeriodComments.monthly.map(
              (item) =>
                `${item.month.split("-")[1]}, ${item.month.split("-")[0]}`
            ),
        },
      });
      setSeries([
        {
          name: "Comments",
          data: commentsData.data.getComments.timePeriodComments.monthly.map(
            (item) => item.count
          ),
        },
      ]);
    }
    setComments(commentsData.data.getComments.comments);
  };
  const updateCommentsTimePeriod = (val) => {
    let commentsTimePeriodOptionsMod = commentsTimePeriodOptions;
    commentsTimePeriodOptionsMod.filter(
      (item) => item.active
    )[0].active = false;
    commentsTimePeriodOptionsMod.filter(
      (item) => item.value === val
    )[0].active = true;
    console.log(commentsTimePeriodOptionsMod);
    setCommentsTimePeriodOptions(commentsTimePeriodOptionsMod);
    setIsCommentsDateDropDownActive(false);
    fetchData();
  };
  return (
    <Layout channelData={channelData} fetchData={fetchData}>
      <div
        css={css`
          padding-top: 8%;
        `}
        className="page__row page__row_border"
      >
        <div className="page__col">
          <div className="details details_statements">
            <header
              css={css`
                margin-bottom: 1em;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
              `}
            >
              <a
                href="/"
                css={css`
                  margin-right: 16px;
                  color: inherit;
                  background: #2e3138;
                  padding: 0 8px;
                  border-radius: 100%;
                  &:hover {
                    background: #3d4048;
                  }
                `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  css={css`
                    height: 24px;
                    width: 24px;
                    margin-top: 8px;
                  `}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
              <h1>
                How <span className="color-purple">super</span> is your{" "}
                <span className="color-purple">superfan</span>?
              </h1>
            </header>
            <div className="details__container">
              <div className="details__title h6 mobile-hide">
                ⏳ Comments over time
              </div>
              <div
                className={
                  isCommentsDateDropDownActive
                    ? `dropdown js-dropdown active`
                    : `dropdown js-dropdown`
                }
              >
                <div
                  onClick={() => {
                    setIsCommentsDateDropDownActive(
                      !isCommentsDateDropDownActive
                    );
                  }}
                  className="dropdown__head js-dropdown-head"
                >
                  {
                    commentsTimePeriodOptions.filter((item) => item.active)[0]
                      .label
                  }
                </div>
                <div className="dropdown__body js-dropdown-body">
                  {commentsTimePeriodOptions
                    .filter((item) => !item.active)
                    .map((item) => (
                      <a
                        className="dropdown__item"
                        onClick={() => updateCommentsTimePeriod(item.value)}
                      >
                        <div className="dropdown__title title">
                          {item.label}
                        </div>
                      </a>
                    ))}
                </div>
              </div>
              <div className="details__row">
                <div className="details__col">
                  <div className="details__top">
                    <div
                      css={css`
                        margin-bottom: 0.4em;
                      `}
                    >
                      <img
                        css={css`
                          border-radius: 20px;
                          width: 60%;
                        `}
                        src={fanData.authorAvatarUrl}
                        alt={`${fanData.author}'s profile pic`}
                      />
                    </div>
                    <div>
                      <h2>{fanData.author}</h2>
                      <p>
                        <a
                          css={css`
                            color: inherit;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                          `}
                          href={fanData.authorChannelUrl}
                          target="_blank"
                        >
                          Youtube profile{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            css={css`
                              height: 16px;
                              width: 16px;
                              margin-left: 2px;
                            `}
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="details__bottom">
                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-gap: 4px;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          flex-flow: row wrap;
                          width: 100%;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {" "}
                        <div className="details__number h1">
                          {fanData.commentCount}
                        </div>
                      </div>
                      <div
                        css={css`
                          display: flex;
                          flex-flow: row wrap;
                          width: 100%;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        {" "}
                        <div
                          className="color-green"
                          css={css`
                            line-height: 14px;
                          `}
                        >
                          Total comments
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details__col">
                  <div
                    className="details__chart details__chart_report details__chart_report__xl"
                    css={css`
                      height: 100%;
                      min-height: 100%;
                    `}
                  >
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page__stat page__stat_sm">
            <div className="sorting mobile-show">
              <div className="sorting__row">
                <div className="sorting__col">
                  <div className="sorting__line">
                    <div className="sorting__actions">
                      <button className="sorting__action">
                        <svg className="icon icon-print">
                          <use xlinkHref="img/sprite.svg#icon-print"></use>
                        </svg>
                      </button>
                      <button className="sorting__action">
                        <svg className="icon icon-download-fat">
                          <use xlinkHref="img/sprite.svg#icon-download-fat"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="sorting mobile-hide">
              <div className="sorting__actions">
                <button className="sorting__action">
                  <svg className="icon icon-print">
                    <use xlinkHref="img/sprite.svg#icon-print"></use>
                  </svg>
                </button>
                <button className="sorting__action">
                  <svg className="icon icon-download-fat">
                    <use xlinkHref="img/sprite.svg#icon-download-fat"></use>
                  </svg>
                </button>
              </div>
            </div> */}
            <div className="products products_statement">
              <div className="products__title h6">
                ✨ Top comments by {fanData.author}
              </div>
              <div className="products__info caption">
                Please note: This is based on the number of comments made by
                fans on your videos.
              </div>
              <div className="products__table">
                {comments.map((comment) => (
                  <div
                    className="products__row"
                    css={css`
                      grid-template-columns: 1fr auto auto;
                    `}
                  >
                    <div
                      className="products__cell color-gray"
                      css={css`
                        @media (max-width: 450px) {
                          font-size: 12px;
                        }
                      `}
                    >
                      17 Aug 2020
                    </div>
                    <div className="products__cell">
                      <div
                        css={css`
                          display: grid;
                          grid-template-rows: auto auto;
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            flex-flow: row wrap;
                            width: 100%;
                          `}
                        >
                          <p>“{comment.content}”</p>
                        </div>
                        <div
                          css={css`
                            display: flex;
                            flex-flow: row wrap;
                            width: 100%;
                          `}
                        >
                          <div
                            css={css`
                              display: grid;
                              grid-template-columns: auto auto;
                              grid-gap: 8px;
                            `}
                          >
                            <div
                              css={css`
                                display: flex;
                                flex-flow: row wrap;
                              `}
                            >
                              <span
                                css={css`
                                  opacity: 0.6;
                                  font-size: 12px;
                                `}
                              >
                                {moment(comment.publishedAt * 1000).fromNow()}
                              </span>
                            </div>
                            <div
                              css={css`
                                display: flex;
                                flex-flow: row wrap;
                              `}
                            >
                              <span
                                css={css`
                                  opacity: 0.6;
                                  font-size: 12px;
                                `}
                              >
                                {comment.likes} likes
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="products__cell text-right">
                      <div className="products__status">
                        <a
                          href={`https://www.youtube.com/watch?v=${comment.videoId}`}
                          target="_blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 8L18 12L14 16"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18 12L7.6709 12"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="products__more">
                <button className="products__btn btn btn_black">
                  Load More
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FanProfile;
