/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { client } from "../utils/graphql";
import { nFormatter, truncate } from "../utils/helpers";
import Layout from "./Layout";
require("../template.css");

const GET_CHANNEL_DATA = gql`
  query ($token: String, $startTime: Int!, $endTime: Int!) {
    getChannelData(input: { token: $token }) {
      id
      name
      status
      videoCount
      subscriberCount
      commentCount
      lastPullTimestamp
      authors(
        input: {
          pagination: { page: 1, limit: 20 }
          timePeriodComments: { Start: $startTime, End: $endTime }
        }
      ) {
        commentCount
        author
        authorChannelUrl
        authorAvatarUrl
      }
      timePeriodComments(input: { Start: $startTime, End: $endTime }) {
        monthly {
          month
          count
        }
        weekly {
          week
          count
        }
      }
    }
  }
`;

const Home = () => {
  const [channelData, setChannelData] = useState({ authors: [] });
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [options, setOptions] = useState({
    xaxis: {
      categories: ["Oct", "Nov", "Dec", "Jan", "Feb"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    colors: ["#6C5DD3"],
    chart: {
      height: "100%",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#E4E4E4",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        left: 10,
        right: 0,
        bottom: 0,
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      shared: false,
    },
    yaxis: {
      axisBorder: {
        color: "#E4E4E4",
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Comments",
      data: [40, 16, 38, 30, 25],
    },
  ]);
  const [commentsTimePeriod, setCommentsTimePeriod] = useState(12);
  const [commentsTimePeriodOptions, setCommentsTimePeriodOptions] = useState([
    { label: "Last 30 days", value: 30, active: false },
    { label: "Last 3 months", value: 90, active: false },
    { label: "Last 6 months", value: 180, active: false },
    { label: "Last 12 months", value: 365, active: true },
  ]);
  const [isCommentsDateDropDownActive, setIsCommentsDateDropDownActive] =
    useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("changed");
    fetchData();
  }, [commentsTimePeriod]);

  const updateCommentsTimePeriod = (val) => {
    let commentsTimePeriodOptionsMod = commentsTimePeriodOptions;
    commentsTimePeriodOptionsMod.filter(
      (item) => item.active
    )[0].active = false;
    commentsTimePeriodOptionsMod.filter(
      (item) => item.value === val
    )[0].active = true;
    console.log(commentsTimePeriodOptionsMod);
    setCommentsTimePeriodOptions(commentsTimePeriodOptionsMod);
    setIsCommentsDateDropDownActive(false);
    fetchData();
  };

  const fetchData = async () => {
    localStorage.setItem("darkMode", "on");
    const { data } = await client.query({
      query: GET_CHANNEL_DATA,
      variables: {
        token: localStorage.getItem("token"),
        startTime: Math.floor(
          new Date(
            new Date().setDate(
              new Date().getDate() -
                commentsTimePeriodOptions.filter((item) => item.active)[0].value
            )
          ).getTime() / 1000
        ),
        endTime: Math.floor(new Date().getTime() / 1000),
      },
    });
    console.log(data.getChannelData);
    if (data.getChannelData.commentCount > 0) {
      if (
        commentsTimePeriodOptions.filter((item) => item.active)[0].value === 30
      ) {
        setOptions({
          ...options,
          xaxis: {
            ...options.xaxis,
            categories: data.getChannelData.timePeriodComments.weekly.map(
              (item) => `Week - ${item.week.split("-")[1]}`
            ),
          },
        });
        setSeries([
          {
            name: "Comments",
            data: data.getChannelData.timePeriodComments.weekly.map(
              (item) => item.count
            ),
          },
        ]);
      } else {
        setOptions({
          ...options,
          xaxis: {
            ...options.xaxis,
            categories: data.getChannelData.timePeriodComments.monthly.map(
              (item) =>
                `${item.month.split("-")[1]}, ${item.month.split("-")[0]}`
            ),
          },
        });
        setSeries([
          {
            name: "Comments",
            data: data.getChannelData.timePeriodComments.monthly.map(
              (item) => item.count
            ),
          },
        ]);
      }
    } else {
      setShowEmptyState(true);
    }
    localStorage.setItem(
      "lastFetchedTimeStamp",
      data.getChannelData.lastPullTimestamp
    );
    setChannelData(data.getChannelData);
  };

  return (
    <Layout channelData={channelData} fetchData={fetchData}>
      <div
        css={css`
          padding-top: 8%;
        `}
        className="page__row page__row_border"
      >
        {showEmptyState ? (
          <>
            {" "}
            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                padding: 2em 0;
                width: 100%;
              `}
            >
              <img
                css={css`
                  height: 320px;
                  width: auto;
                `}
                alt="Empty comments placeholder"
                src="https://challenges-storage.fra1.cdn.digitaloceanspaces.com/assets/no-comments-found.png"
              />
              <h3
                css={css`
                  width: 100%;
                  margin-top: 1em;
                  text-align: center;
                  font-size: 24px;
                  font-family: "Lexend", sans-serif;
                `}
              >
                Oops! We couldn't find any comments in your channel 😥
              </h3>
            </div>
          </>
        ) : (
          <div className="page__col">
            <div className="details details_statements">
              <div className="details__container">
                <div className="details__title h6 mobile-hide">
                  Comments by your fans 💬
                </div>
                <div
                  className={
                    isCommentsDateDropDownActive
                      ? `dropdown js-dropdown active`
                      : `dropdown js-dropdown`
                  }
                >
                  <div
                    onClick={() => {
                      setIsCommentsDateDropDownActive(
                        !isCommentsDateDropDownActive
                      );
                    }}
                    className="dropdown__head js-dropdown-head"
                  >
                    {
                      commentsTimePeriodOptions.filter((item) => item.active)[0]
                        .label
                    }
                  </div>
                  <div className="dropdown__body js-dropdown-body">
                    {commentsTimePeriodOptions
                      .filter((item) => !item.active)
                      .map((item) => (
                        <a
                          className="dropdown__item"
                          onClick={() => updateCommentsTimePeriod(item.value)}
                        >
                          <div className="dropdown__title title">
                            {item.label}
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
                <div className="details__row">
                  <div
                    css={css`
                      width: 100%;
                    `}
                  >
                    <div className="details__chart details__chart_report">
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
                <div className="details__list details__list_three">
                  <div className="details__item">
                    <div className="details__head">
                      <div className="details__preview bg-purple">
                        <img
                          className="details__pic"
                          src="../img/folder.svg"
                          alt=""
                        />
                      </div>
                      <div className="details__text caption-sm">Superfans</div>
                    </div>
                    <div className="details__counter h4">
                      {" "}
                      {nFormatter(channelData.authors.length, 2)}
                    </div>
                    <div className="details__indicator">
                      <div
                        className="details__progress bg-purple"
                        css={css`
                          width: 55%;
                        `}
                      ></div>
                    </div>
                  </div>
                  <div className="details__item">
                    <div className="details__head">
                      <div className="details__preview bg-pink">
                        <img
                          className="details__pic"
                          src="../img/activity.svg"
                          alt=""
                        />
                      </div>
                      <div className="details__text caption-sm">Followers</div>
                    </div>
                    <div className="details__counter h4">
                      {nFormatter(channelData.subscriberCount, 2)}
                    </div>
                    <div className="details__indicator">
                      <div
                        className="details__progress bg-pink"
                        css={css`
                          width: 52%;
                        `}
                      ></div>
                    </div>
                  </div>
                  <div className="details__item">
                    <div className="details__head">
                      <div className="details__preview bg-blue">
                        <img
                          className="details__pic"
                          src="../img/bag.svg"
                          alt=""
                        />
                      </div>
                      <div className="details__text caption-sm">Comments</div>
                    </div>
                    <div className="details__counter h4">
                      {channelData.commentCount}
                    </div>
                    <div className="details__indicator">
                      <div
                        className="details__progress bg-blue"
                        css={css`
                          width: 55%;
                        `}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page__stat page__stat_sm">
              {/* <div className="sorting mobile-show">
                <div className="sorting__row">
                  <div className="sorting__col">
                    <div className="sorting__line">
                      <div className="sorting__actions">
                        <button className="sorting__action">
                          <svg className="icon icon-print">
                            <use xlinkHref="../img/sprite.svg#icon-print"></use>
                          </svg>
                        </button>
                        <button className="sorting__action">
                          <svg className="icon icon-download-fat">
                            <use xlinkHref="../img/sprite.svg#icon-download-fat"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="sorting mobile-hide">
                <div className="sorting__actions">
                  <button className="sorting__action">
                    <svg className="icon icon-print">
                      <use xlinkHref="../img/sprite.svg#icon-print"></use>
                    </svg>
                  </button>
                  <button className="sorting__action">
                    <svg className="icon icon-download-fat">
                      <use xlinkHref="../img/sprite.svg#icon-download-fat"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="products products_statement">
                <div className="products__title h6 mobile-hide">
                  ✨ Your top fans
                </div>
                <div className="products__info caption mobile-hide">
                  Please note: This is based on the number of comments made by
                  fans on your videos.
                </div>
                <div className="products__table">
                  {channelData.authors.map((author, index) =>
                    index < 5 ? (
                      <div className="products__row">
                        {/* <div className="products__cell color-gray">
                          17 Aug 2020
                        </div> */}
                        <div
                          className="products__cell"
                          css={css`
                            padding-left: 0;
                            display: block !important;
                          `}
                        >
                          <a
                            css={css`
                              display: grid;
                              grid-template-columns: auto 1fr;
                              grid-gap: 8px;
                              color: inherit;
                              @media (min-width: 700px) {
                                grid-gap: 24px;
                              }
                            `}
                            href={`/superfan/${
                              author.authorChannelUrl.split(
                                "http://www.youtube.com/channel/"
                              )[1]
                            }`}
                          >
                            <div
                              css={css`
                                display: flex;
                                flex-flow: row wrap;
                                width: 100%;
                                align-items: center;
                              `}
                            >
                              <img
                                css={css`
                                  height: 48px;
                                  width: auto;
                                  border-radius: 16px;
                                `}
                                src={author.authorAvatarUrl}
                              />
                            </div>
                            <div
                              css={css`
                                display: flex;
                                flex-flow: row wrap;
                                width: 100%;
                                align-items: center;
                              `}
                            >
                              <p
                                css={css`
                                  font-size: 1em;
                                `}
                              >
                                {author.author}
                              </p>
                            </div>
                          </a>
                        </div>
                        {/* <div className="products__cell">
                          <a
                            css={css`
                              color: #ccc;
                              &:hover {
                                color: #fff;
                              }
                            `}
                            href={author.authorChannelUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {truncate(author.authorChannelUrl, 48)}
                          </a>
                        </div> */}
                        <div className="products__cell text-right">
                          <div className="products__status">
                            <div>
                              <p className="color-green">
                                {author.commentCount}
                              </p>
                              <p>
                                <span className="caption">Comments</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
                <div className="products__more">
                  <a
                    href="/leaderboard"
                    className="products__btn btn btn_black"
                  >
                    View leaderboard
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Home;
