/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import GoogleLogin from "react-google-login";
import { gql, useMutation } from "@apollo/client";
import logo from "../img/login-logo.png";
import bg from "../img/login-bg.png";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import signin from "../img/signin-lottie.json";
import { productionClientId, stagingClientID } from "../utils/helpers";

const AUTHENTICATE_USER = gql`
  mutation login($code: String!) {
    login(input: { accessCode: $code }) {
      token
    }
  }
`;
const Signin = () => {
  const [authenticateUser, { data, loading, error }] =
    useMutation(AUTHENTICATE_USER);

  // Handle google login response
  const responseGoogle = (response) => {
    authenticateUser({ variables: { code: response.code } })
      .then((response) => {
        if (response.data.login.token) {
          localStorage.setItem("token", response.data.login.token);
          window.location.reload();
        } else {
          toast.error(
            `There is no YouTube channel associated with that Google account!`
          );
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          `Psst.. We need those permissions to discover your super fans`
        );
      });
  };

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        @media (min-width: 700px) {
          grid-template-columns: 1fr 1fr;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          background: url(${bg});
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          padding-bottom: 16px;
          @media (min-width: 700px) {
            min-height: 100vh;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            min-height: 100%;
            width: 100%;
            background-color: #242731;
            opacity: 0.8;
            position: absolute;
            top: 0;
            left: 0;
            @media (min-width: 700px) {
              min-height: 100vh;
            }
          `}
        ></div>
        <img
          src={logo}
          css={css`
            height: 80px;
            width: auto;
            z-index: 99;
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-flow: column wrap;
          justify-content: flex-start;
          padding-top: 4%;
          align-items: center;
          height: 88vh;
          width: 100%;
          background-color: #3f8cff;
          @media (min-width: 700px) {
            background-image: url(https://challenges-storage.fra1.cdn.digitaloceanspaces.com/assets/sign-in-image.png);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: right bottom;
            justify-content: center;
            padding-top: 0;
            height: 100vh;
          }
        `}
      >
        <div>
          <Lottie
            css={css`
              height: 180px;
              width: 180px;
              @media (min-width: 700px) {
                height: 280px;
                width: 280px;
              }
            `}
            animationData={signin}
          />
        </div>
        <div
          css={css`
            padding: 1rem 4em;
          `}
        >
          <p
            css={css`
              text-align: center;
              font-family: "Lexend", sans-serif;
              font-size: 16px;
              font-weight: 600;
            `}
          >
            We need access to your youtube account and the comment(s) in your
            video(s) to analyze and give you a more authentic list of your super
            fans.
          </p>
        </div>
        <GoogleLogin
          clientId={productionClientId}
          buttonText="Continue with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          prompt="consent"
          responseType="code"
          scope="openid https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.force-ssl"
          accessType="offline"
        />
        <div
          css={css`
            padding: 1rem 3.2em;
          `}
        >
          <p
            css={css`
              text-align: center;
              font-family: "Lexend", sans-serif;
              font-size: 12px;
              opacity: 0.6;
              margin-top: 8px;
            `}
          >
            We use this access only to show your list of superfans and nothing
            else. <br />
            We do not make any changes to your YouTube account, videos, or
            comments.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;
