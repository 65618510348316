import { Routes, Route, Link } from "react-router-dom";
import Signin from "./Signin";

const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Signin />} />
    </Routes>
  );
};

export default PublicRouter;
