export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const stagingURL = "https://alchemy-api.vloggerstoolbox.com/query";

export const productionURL = "https://alchemy-api.creatorstack.com/query";

export const stagingClientID =
  "310022810979-1pd19bfk2rjaemr64hn15om4rdm89eta.apps.googleusercontent.com";

export const productionClientId =
  "171424956263-vjiq8rdnjlob8u1ibe0ov1lv029jbcgd.apps.googleusercontent.com";
