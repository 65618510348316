/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { client } from "../utils/graphql";
import Layout from "./Layout";
require("../template.css");

const GET_CHANNEL_DATA = gql`
  query ($token: String, $startTime: Int!, $endTime: Int!) {
    getChannelData(input: { token: $token }) {
      id
      name
      status
      videoCount
      subscriberCount
      commentCount
      lastPullTimestamp
      authors(
        input: {
          pagination: { page: 1, limit: 20 }
          timePeriodComments: { Start: $startTime, End: $endTime }
        }
      ) {
        commentCount
        author
        authorChannelUrl
        authorAvatarUrl
      }
    }
  }
`;

const Leaderboard = () => {
  const [channelData, setChannelData] = useState({ authors: [] });
  useEffect(() => {
    fetchData();
  }, []);
  const [timePeriodOptions, setTimePeriodOptions] = useState([
    { label: "Last 30 days", value: 30, active: false },
    { label: "Last 6 months", value: 180, active: false },
    { label: "Last 12 months", value: 365, active: true },
  ]);
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
    colors: ["#6C5DD3"],
    plotOptions: {
      bar: {
        columnWidth: "100%",
        borderRadius: 8,
        horizontal: true,
        barHeight: "88%",
        style: {
          fontSize: "24px",
          fontFamily: "Lexend, sans-serif",
          fontWeight: "bold",
        },
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]);
  const updateTimePeriod = (val) => {
    let timePeriodOptionsMod = timePeriodOptions;
    timePeriodOptionsMod.filter((item) => item.active)[0].active = false;
    timePeriodOptionsMod.filter((item) => item.value === val)[0].active = true;
    console.log(timePeriodOptionsMod);
    setTimePeriodOptions(timePeriodOptionsMod);
    fetchData();
  };

  const fetchData = async () => {
    localStorage.setItem("darkMode", "on");
    const { data } = await client.query({
      query: GET_CHANNEL_DATA,
      variables: {
        token: localStorage.getItem("token"),
        startTime: Math.floor(
          new Date(
            new Date().setDate(
              new Date().getDate() -
                timePeriodOptions.filter((item) => item.active)[0].value
            )
          ).getTime() / 1000
        ),
        endTime: Math.floor(new Date().getTime() / 1000),
      },
    });
    console.log(data.getChannelData);
    setOptions({
      colors: ["#6C5DD3"],
      chart: {
        height: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        borderColor: "#E4E4E4",
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 0,
          left: 10,
          right: 0,
          bottom: 0,
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 0.9,
          },
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return `${val} Comment(s)`;
        },
        distributed: false,
        offsetX: 60,
        offsetY: 0,
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
          borderRadius: 8,
          horizontal: true,
          barHeight: "88%",
          style: {
            fontSize: "24px",
            fontFamily: "Lexend, sans-serif",
            fontWeight: "bold",
          },
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        categories: data.getChannelData.authors.map((item) => item.author),
        labels: {
          show: true,
          style: {
            colors: ["#ffffff"],
          },
        },
      },
      yaxis: {
        axisBorder: {
          color: "#E4E4E4",
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: ["#ffffff"],
          },
        },
      },
    });
    setSeries([
      {
        name: "comments-count",
        data: data.getChannelData.authors.map((item) => item.commentCount),
      },
    ]);
    setChannelData(data.getChannelData);
    localStorage.setItem(
      "lastFetchedTimeStamp",
      data.getChannelData.lastPullTimestamp
    );
  };

  return (
    <Layout channelData={channelData} fetchData={fetchData}>
      <div
        css={css`
          padding-top: 8%;
        `}
        className="page__row page__row_border"
      >
        <div
          className="page__col"
          css={css`
            max-width: 100% !important;
            width: 100% !important;
            flex-basis: 100% !important;
          `}
        >
          <div className="details details_statements">
            <header
              css={css`
                margin-bottom: 1em;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
              `}
            >
              <a
                href="/"
                css={css`
                  margin-right: 16px;
                  color: inherit;
                  background: #2e3138;
                  padding: 0 8px;
                  border-radius: 100%;
                  &:hover {
                    background: #3d4048;
                  }
                `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  css={css`
                    height: 24px;
                    width: 24px;
                    margin-top: 8px;
                  `}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
              <h1
                css={css`
                  @media (max-width: 450px) {
                    font-size: 25px;
                  }
                `}
              >
                Your <span className="color-purple">superfan</span> leaderboard
              </h1>
            </header>
            <div
              css={css`
                @media (max-width: 450px) {
                  padding: 16px;
                }
              `}
              className="details__container"
            >
              <div
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                `}
              >
                <div className="details__title h6 mobile-hide">
                  🚀 Number of comments by fans
                </div>
                <div className="page__nav">
                  {timePeriodOptions.map((item) => (
                    <a
                      className={
                        item.active ? `page__link active` : `page__link`
                      }
                      onClick={() => {
                        updateTimePeriod(item.value);
                      }}
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
              </div>
              <div className="details__row">
                <div
                  className="details__col"
                  css={css`
                    max-width: 100% !important;
                    width: 100% !important;
                    flex-basis: 100% !important;
                  `}
                >
                  <div className="details__chart details__chart_report details__chart_report__xl">
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: 8%;
          max-width: 100% !important;
          width: 100% !important;
          flex-basis: 100% !important;
        `}
        className="page__row page__row_border"
      >
        <div
          className="page__col"
          css={css`
            max-width: 100% !important;
            width: 100% !important;
            flex-basis: 100% !important;
          `}
        >
          <h2>
            Checkout more metrics, find more super fans and receive a
            personalized report
          </h2>
          <div
            css={css`
              justify-content: space-between;
              display: flex;
              flex-flow: row wrap;
              margin-top: 16px;
            `}
            className="field__wrap"
          >
            <input
              css={css`
                width: 78%;
                @media (max-width: 450px) {
                  width: 100%;
                  margin-bottom: 16px;
                }
              `}
              className="field__input"
              type="text"
              placeholder="supercreator@gothamcity.com"
            />
            <button
              css={css`
                width: 20%;
                border-radius: 8px;
                @media (max-width: 450px) {
                  width: 100%;
                }
              `}
              className="popup__btn btn btn_purple"
              type="submit"
            >
              Request access
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Leaderboard;
