/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ApolloClient, InMemoryCache, gql, useLazyQuery } from "@apollo/client";
import AuthenticatedRouter from "./protected/AuthenticatedRouter";
import PublicRouter from "./public/PublicRouter.js";
import { Toaster } from "react-hot-toast";
import { productionURL, stagingURL } from "./utils/helpers";

const client = new ApolloClient({
  uri: productionURL,
  cache: new InMemoryCache(),
});

//TODO: Add poller here
const GET_CHANNEL_FETCH_STATUS = gql`
  query channelFetchStatus($token: String) {
    getChannelFetchStatus(input: { token: $token }) {
      id
      status
    }
  }
`;

const App = () => {
  const [getChannelStatus, { l, e, payload }] = useLazyQuery(
    GET_CHANNEL_FETCH_STATUS
  );

  // const getChannelFetchStatus = () => {
  //   console.log("clicked!");
  //   console.log(payload);
  //   getChannelStatus({ variables: { token: localStorage.getItem("token") } });
  //   console.log(payload);
  // };

  if (localStorage.getItem("token")) {
    return (
      <>
        <div>
          <Toaster />
        </div>
        <AuthenticatedRouter />
      </>
    );
  }

  return (
    <>
      <div>
        <Toaster />
      </div>
      <PublicRouter />
    </>
  );
};

export default App;
