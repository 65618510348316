/** @jsxImportSource @emotion/react */

import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { client } from "../utils/graphql";
import { css } from "@emotion/react";
import Chart from "react-apexcharts";

const GET_CHANNEL_DATA = gql`
  query ($token: String) {
    getChannelData(input: { token: $token }) {
      id
      name
      status
      videoCount
      subscriberCount
      lastPullTimestamp
      authors(input: { limit: 10, page: 1 }) {
        commentCount
        author
        authorChannelUrl
      }
    }
  }
`;

const GET_COMMENTS = gql`
  query ($token: String, $authorChannelUrl: String!) {
    getComments(
      input: {
        token: $token
        authorChannelUrl: $authorChannelUrl
        pagination: { limit: 10, page: 1 }
      }
    ) {
      id
      content
      videoId
      likes
      publishedAt
    }
  }
`;

//TODO: Add poller here
const REFETCH_CHANNEL_DATA = gql`
  mutation initiateChannelDataFetch($token: String!) {
    initiateChannelDataFetch(input: { token: $token }) {
      fetchStatus {
        id
        status
        additionalInfo
      }
    }
  }
`;

const Playground = () => {
  const [
    refetchChannelData,
    { updatedChannelData, fetchingChannelData, errorFetchingChannelData },
  ] = useMutation(REFETCH_CHANNEL_DATA);
  const [channelData, setChannelData] = useState({});
  const [comments, setComments] = useState();
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  });
  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]);

  const initiateChannelDataRefetch = () => {
    refetchChannelData({
      variables: { token: localStorage.getItem("token") },
    }).then((response) => {
      console.log("updated channel data");
      console.log(response.data.initiateChannelDataFetch.fetchStatus);
    });
  };

  return (
    <div>
      <div>
        <p>
          Data last fetched on :{" "}
          {Date(channelData.lastPullTimestamp).toString()}
        </p>
        <button onClick={initiateChannelDataRefetch}>Refetch Data</button>
      </div>
      <div>
        <h3>Channel data</h3>
        <pre>{JSON.stringify(channelData, null, 2)}</pre>
      </div>
      <div>
        <h3>Comments</h3>
        <pre>{JSON.stringify(comments, null, 2)}</pre>
      </div>
      <div>
        <button
          onClick={async () => {
            const { data } = await client.query({
              query: GET_CHANNEL_DATA,
              variables: {
                token: localStorage.getItem("token"),
              },
            });
            console.log(data.getChannelData.id);
            setChannelData(data.getChannelData);
            setOptions({
              plotOptions: {
                bar: {
                  columnWidth: "100%",
                  borderRadius: 8,
                  horizontal: true,
                  barHeight: "90%",
                  style: {
                    fontSize: "16px",
                    fontFamily: "Lexend, sans-serif",
                    fontWeight: "bold",
                    colors: undefined,
                  },
                },
              },
              colors: ["#6C5DD3"],
              chart: {
                height: "100%",
                type: "bar",
                toolbar: {
                  show: false,
                },
              },
              grid: {
                borderColor: "#E4E4E4",
                strokeDashArray: 0,
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
                padding: {
                  top: 0,
                  left: 10,
                  right: 0,
                  bottom: 0,
                },
              },
              states: {
                hover: {
                  filter: {
                    type: "darken",
                    value: 0.9,
                  },
                },
              },
              stroke: {
                curve: "smooth",
              },
              xaxis: {
                categories: data.getChannelData.authors.map(
                  (item) => item.author
                ),
              },
            });
            setSeries([
              {
                name: "comments-count",
                data: data.getChannelData.authors.map(
                  (item) => item.commentCount
                ),
              },
            ]);
          }}
        >
          getChannelData
        </button>
      </div>
      <div>
        <button
          onClick={async () => {
            const { data } = await client.query({
              query: GET_COMMENTS,
              variables: {
                token: localStorage.getItem("token"),
                authorChannelUrl: channelData.authors[0].authorChannelUrl,
              },
            });
            console.log(data);
            setComments(data);
          }}
        >
          getComments
        </button>
      </div>

      <div>
        <Chart options={options} series={series} type="bar" width="500" />
      </div>
    </div>
  );
};

export default Playground;
