/** @jsxImportSource @emotion/react */

import { Routes, Route, Link } from "react-router-dom";
import { css } from "@emotion/react";

import Home from "./Home";
import Playground from "./Playground";
import Leaderboard from "./Leaderboard";
import FanProfile from "./FanProfile";

const AuthenticatedRouter = () => {
  return (
    <div>
      {/* <header
        css={css`
          background: hotpink;
          padding: 2em 4em;
        `}
      >
        <h1
          css={css`
            margin: 0;
            color: #fff;
          `}
        >
          🥷 We are authenticated!{" "}
        </h1>
      </header> */}
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="superfan/:id" element={<FanProfile />} />
          <Route path="playground" element={<Playground />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthenticatedRouter;
