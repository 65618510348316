/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import logo_1 from "../img/logo.png";
import logo from "../img/login-logo.png";
import logoSm from "../img/logo-sm.png";
import logoWhite from "../img/logo-white.png";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import loader from "../img/loader.json";

//TODO: Add poller here
const REFETCH_CHANNEL_DATA = gql`
  mutation initiateChannelDataFetch($token: String!) {
    initiateChannelDataFetch(input: { token: $token }) {
      fetchStatus {
        id
        status
        additionalInfo
      }
    }
  }
`;
const GET_CHANNEL_FETCH_STATUS = gql`
  query channelFetchStatus($token: String) {
    getChannelFetchStatus(input: { token: $token }) {
      id
      status
    }
  }
`;
const Layout = (props) => {
  const [lastFetchedTimeStamp, setLastFetchedTimeStamp] = useState(
    parseInt(localStorage.getItem("lastFetchedTimeStamp")) * 1000
  );

  const { loading, error, data, startPolling, stopPolling } = useQuery(
    GET_CHANNEL_FETCH_STATUS,
    {
      variables: { token: localStorage.getItem("token") },
    }
  );
  const [
    refetchChannelData,
    { updatedChannelData, fetchingChannelData, errorFetchingChannelData },
  ] = useMutation(REFETCH_CHANNEL_DATA);

  const [fetchingData, setFetchingData] = useState(false);

  const initiateChannelDataRefetch = () => {
    refetchChannelData({
      variables: { token: localStorage.getItem("token") },
    }).then((response) => {
      setFetchingData(true);
      startPolling(500);
    });
  };
  useEffect(() => {
    if (data !== undefined && data.getChannelFetchStatus.status === "success") {
      console.log(props.channelData);
      stopPolling();
      setFetchingData(false);
      props.fetchData();
    }
  }, [data]);

  return (
    <>
      <div className="page">
        <div className="sidebar">
          <div className="sidebar__top">
            <button className="sidebar__close">
              <svg className="icon icon-close">
                <use xlinkHref="../img/sprite.svg#icon-close"></use>
              </svg>
            </button>
            <a className="sidebar__logo" href="/">
              <img
                className="sidebar__pic sidebar__pic_black"
                src={logo}
                alt=""
              />
              <img
                className="sidebar__pic sidebar__pic_white"
                src={logo}
                alt=""
              />
            </a>
            <button className="sidebar__burger"></button>
          </div>
          <div className="sidebar__wrapper">
            <div className="sidebar__inner">
              <a className="sidebar__logo" href="/">
                <img className="sidebar__pic" src={logo} alt="" />
              </a>
              <div className="sidebar__list">
                <div
                  css={css`
                    padding: 16px 20px;
                    background: #2e2f3e;
                    border-radius: 24px;
                    box-shadow: 0px 0px 16px 0px #1212121a;
                  `}
                >
                  <div>
                    <h2
                      css={css`
                        font-family: "Lexend", sans-serif;
                      `}
                    >
                      What next?
                    </h2>
                  </div>
                  <div
                    css={css`
                      margin-top: 0.4em;
                    `}
                  >
                    <p
                      css={css`
                        font-family: "Lexend", sans-serif;
                        font-size: 0.9em;
                      `}
                    >
                      Discovering your super fans is the first step towards a
                      life-long creator fan relationship. Use this newfound
                      information to build your private fan club and let your
                      super fans empower you.
                    </p>
                  </div>
                  <div
                    css={css`
                      margin-top: 1.6em;
                    `}
                  >
                    <a
                      css={css`
                        color: #ff0062;
                        font-weight: 600;
                        font-family: "Lexend", sans-serif;
                        &:hover {
                          color: #5a49ce;
                          cursor: pointer;
                        }
                      `}
                      data-tf-slider="mLHLM7Sk"
                      data-tf-width="550"
                      data-tf-iframe-props="title=Backstage Creator request form"
                      data-tf-medium="snippet"
                    >
                      Claim my backstage
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="sidebar__bottom"
            style={
              fetchingData
                ? { padding: "0" }
                : { padding: "20px 8px 16px 20px" }
            }
          >
            {fetchingData ? (
              <div
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;
                  width: 100%;
                `}
              >
                <Lottie
                  style={{ height: "140px", width: "140px" }}
                  animationData={loader}
                  autoPlay
                  loop={true}
                />
                <p>Fetching data</p>
              </div>
            ) : (
              <div
                css={css`
                  padding-left: 20px;
                `}
              >
                <div
                  css={css`
                    width: 100%;
                  `}
                >
                  <button
                    onClick={initiateChannelDataRefetch}
                    className="btn btn_purple"
                  >
                    Reload metrics
                  </button>
                </div>
                <div>
                  <p>
                    <span
                      css={css`
                        font-size: 12px;
                        opacity: 0.6;
                      `}
                    >
                      Last fetched{" "}
                    </span>
                    {moment(
                      parseInt(localStorage.getItem("lastFetchedTimeStamp")) *
                        1000
                    ).fromNow()}
                  </p>
                </div>
              </div>
            )}
            <div
              css={css`
                width: 100%;
                margin-top: 2rem;
              `}
            >
              <a
                css={css`
                  color: #fafafa;
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: center;
                  align-items: center;
                  &:hover {
                    color: #ccc;
                    cursor: pointer;
                  }
                `}
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.href = "/";
                }}
              >
                Logout
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-2 w-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  css={css`
                    height: 16px;
                    margin-left: 4px;
                  `}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="page__wrapper">
          <div
            css={css`
              padding: 8px 16% 16px 16%;
              background: hsl(226deg 16% 14%);
              @media (min-width: 700px) {
                display: none;
              }
            `}
          >
            <img src={logo} />
          </div>
          <div className="page__center">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
